// Dependencias

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useForm from 'react-hook-form';
import nanoid from 'nanoid';
import * as masks from '../../helpers/masks';
import currencyFormatter from 'currency-formatter';

// Componentes

import { Container, Row, Col } from 'react-bootstrap'
import Header from '../Header'
import Body from '../Body'
import Lockr from 'lockr';

// Imagens

import cardIcon from '../../assets/images/ico-cartao-credito.png';
import SapService from '../../services/SapService';
import LoaderAnimation from '../LoaderAnimation';
import FormCreditCard from '../painel/FormCreditCard';

import { URLS } from '../../helpers/Request';
import { updateDataLayer } from '../../helpers/DataLayer';

const FormCoupon = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cupomActived, setCupomActived] = useState(!!sessionStorage.getItem('LOOVI_VOUCHER_CODE'));
  const [hasVoucher, setHasVoucher] = useState(!!sessionStorage.getItem('LOOVI_VOUCHER_CODE'));
  const { register, handleSubmit, errors, setValue } = useForm()
  const { values } = props

  if (hasVoucher) {
    const coupom = sessionStorage.getItem('LOOVI_VOUCHER_CODE');
    setValue('customerCoupom', coupom);
    values.customerCoupom = coupom;
  }

  if (props.isContation && !cupomActived) {
    const sapData = Lockr.get('Loovi-SAP')
    setCupomActived(true);
    setValue('customerCoupom', sapData.quotation.quotationForm.cmpgnID)
    props.handleChange({ name: 'customerCoupom', value: sapData.quotation.quotationForm.cmpgnID })
  }

  function fireWebToLead(data, tag) {
    let form;

    if (process.env.REACT_APP_BASE_ENVIRONMENT === 'production') {
      form = {
        'oid': '00D4P0000010vdU',
        'retURL': 'https://loovi.com.br/',

        'first_name': data.customerName.split(' ')[0],
        'last_name': data.customerName.split(' ').slice(1).join(' '),
        'email': data.customerEmail,
        'phone': data.customerPhoneNumber,

        'lead_source': 'Site Forms',
        '00N4P00000DLrb0': 'Site Forms',
        '00N6f00000FeUFt': data.customerCoupom,

        '00N4P00000FIE0w': data.customerCEP,
        '00N4P00000FIDzZ': data.customerStreet,
        '00N4P00000FIDzo': data.customerStreetNum,
        '00N6f00000FeUFx': data.customerAdressComplement,
        '00N6f00000FeUFv': data.customerIbgeCode,
        '00N4P00000FIE0X': data.customerNeighborhood,
        '00N4P00000FIE0m': data.customerUFCheck,
        '00N4P00000FIE0c': data.customerCityCheck,

        '00N6f00000FeUFs': data.customerCNPJ.length === 14 ? data.customerCNPJ.replace(/[.\-/]/g, '') : '',
        '00N6f00000FeUFr': data.customerCNPJ.length > 14 ? data.customerCNPJ.replace(/[.\-/]/g, '') : '',
        '00N6f00000FeUG3': '',
        '00N6f00000FeUG2': data.customerCNPJ.length > 14 ? data.customerIE : '',

        '00N6f00000FeUGF': tag,
        'submit': 'Submit',

      }
    } else {
      form = {
        'oid': '00DS0000003A4nP',
        'retURL': 'https://loovi.com.br/',
        'first_name': data.customerName.split(' ')[0],
        'last_name': data.customerName.split(' ').slice(1).join(' '),
        'email': data.customerEmail,
        'phone': data.customerPhoneNumber,
        'lead_source': 'Novo',
        '00N4P00000DLrb0': 'Site Forms',
        '00N6f00000FeUFt': data.customerCoupom,
        '00N4P00000FIE0w': data.customerCEP,
        '00N4P00000FIDzZ': data.customerStreet,
        '00N4P00000FIDzo': data.customerStreetNum,
        '00NS0000009IiYT': data.customerAdressComplement,
        '00NS0000009Xg6T': data.customerIbgeCode,
        '00N4P00000FIE0X': data.customerNeighborhood,
        '00N4P00000FIE0m': data.customerUFCheck,
        '00N4P00000FIE0c': data.customerCityCheck,
        '00NS0000009IiYP': data.customerCNPJ.length === 14 ? data.customerCNPJ.replace(/[.\-/]/g, '') : '',
        '00NS0000009IiYO': data.customerCNPJ.length > 14 ? data.customerCNPJ.replace(/[.\-/]/g, '') : '',
        '00NS0000009X4q1': '',
        '00NS0000009X4q0': data.customerCNPJ.length > 14 ? data.customerIE : '',
        '00N4P00000FIE11': tag,
        'submit': 'Submit',
      }
    }


    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    fetch(`${URLS.WEBTOLEAD}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(form)
    })
      .then((res) => {
        console.log('WEB TO LEAD ENVIADO COM SUCESSO!');
      })
      .catch((err) => {
        console.log('ERRO AO ENVIAR WEB TO LEAD.');
        console.log(err);
      })
  }

  const onSubmit = async (data) => {
    setIsLoading(true);
    const userData = Lockr.get('loovi-metrics');

    try {
      let result;
      let dataForm = { ...data };
      if (cupomActived) {
        dataForm = { customerCoupom: '' };
        result = await SapService.setCoupon('');
        setValue('customerCoupom', '');
        values.customerCoupom = '';
        setHasVoucher(false);
        // props.handleChange({ name: 'customerCoupom', value: '' }
        setCupomActived(false)
        await fireWebToLead({
          ...userData,
          customerCoupom: ''
        }, '');
      } else {
        dataForm = data;

        result = await SapService.setCoupon(data.customerCoupom);
        if (result.sucess !== undefined) {
          if (result.sucess) {
            setCupomActived(true);
            await fireWebToLead({
              ...userData,
              customerCoupom: data.customerCoupom
            }, 'Cupom');
          }
          else alert(result.message)
        }
      }

      if (result) {
        Lockr.set('loovi-metrics', { ...Lockr.get('loovi-metrics'), ...dataForm });
        Lockr.set('Loovi-SAP', { ...result });

        setTimeout(() => {
          window.emitter.emit('PriceChangeEvent');
        }, 20);
      } else {
        alert('Erro de Servidor. Por favor, tente novamente. (0x0003_SAP_CUPOM_onSubmit)');
      }

    } catch (error) {
      console.log('CUPOM', error);
    }

    setIsLoading(false);
    sessionStorage.removeItem('')
  }

  useEffect(() => {
    const sapData = Lockr.get('Loovi-SAP');
    let arrayDados = [];

    sapData.quotation.quotationFormLines.map(item => {
      arrayDados.push([{
        index: 0,
        item_id: item.itemCode,
        item_name: item.itemName,
        affiliation: "",
        coupon: sapData.quotation.quotationForm.cmpgnName,
        discount: sapData.quotation.quotationForm.discTotal,
        item_brand: "Loovi",
        price: item.servPrice,
        quantity: 1
      }]);
      return arrayDados;
    });

    // Verifica se o evento já foi disparado
    const eventFired = localStorage.getItem('InitiateCheckoutFired');

    if (!eventFired) {
      const sapData = Lockr.get('Loovi-SAP');

      if (sapData && sapData.quotation && sapData.quotation.quotationForm && sapData.quotation.quotationFormBusinessPartner && sapData.quotation.quotationFormAddressPayment) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'InitiateCheckout',
          idVendedor: sapData.quotation.quotationForm.slpCode,
          transactionId: sapData.quotation.quotationForm.quotID,
          detailsPayment: {
            currency: 'BRL',
            cupomDesconto: sapData.quotation.quotationForm.cmpgnID,
            valorTotal: sapData.quotation.quotationForm.docTotal,
            valorDesconto: sapData.quotation.quotationForm.discTotal,
          },
          detailsPersonal: {
            nome: sapData.quotation.quotationFormBusinessPartner.cardName,
            cpf: sapData.quotation.quotationFormBusinessPartner.cpf,
            email: sapData.quotation.quotationFormBusinessPartner.email,
            telefone: sapData.quotation.quotationFormBusinessPartner.phone1,
          },
          detailsAddress: {
            rua: sapData.quotation.quotationFormAddressPayment.street,
            numero: sapData.quotation.quotationFormAddressPayment.streetNo,
            bairro: sapData.quotation.quotationFormAddressPayment.block,
            cidade: sapData.quotation.quotationFormAddressPayment.city,
            estado: sapData.quotation.quotationFormAddressPayment.state,
            cep: sapData.quotation.quotationFormAddressPayment.zipCode,
          },
          itens: [sapData.items], 
        });
        localStorage.setItem('InitiateCheckoutFired', 'true');
      } else {
        console.error('sapData ou suas propriedades estão indefinidos.');
      }
    }
  }, [])
  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoaderAnimation show={isLoading} />
      <label>
        {cupomActived && !values.customerCoupom.includes("COBRE")
          ? (
            <div className="loovi-cupom d-flex justify-content-between">
              {(values.customerCoupom === "" && props.isContation) ?
                (
                  <></>
                )
                : (<h5 id="cupom-desconto">Cupom de desconto: {values.customerCoupom}</h5>)
              }

              {(!props.isContation) && (
                <ButtonBack className="loovi-link-button loovi-link-button-payment" onClick={handleSubmit(onSubmit)} type="submit">Remover</ButtonBack>
              )}
            </div>
          ) : (
            <></>
          )}
      </label>
    </form>
  )
}

export function FormPayment(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [sapData, setSapData] = useState(Lockr.get('Loovi-SAP'));
  const { values } = props

  const buildingShipping = values.customerShippingStreet ? (values.customerShippingAdressComplement ? ` - ${values.customerShippingAdressComplement}` : '') : (values.customerAdressComplement ? ` - ${values.customerAdressComplement}` : '')

  /*   const buildingShipping = (values.customerAdressComplement || values.customerShippingAdressComplement)
      ? ` - ${values.customerShippingAdressComplement || values.customerAdressComplement}`
      : ''; */

  useEffect(() => {
    window._etmc.push(["setOrgId", "110005701"]);
    window._etmc.push(["trackCart", {
      "cart": [
        { "item": "LOOVI_PLANO_001", "quantity": "1", "price": "99,90", "unique_id": nanoid(12) },
        { "item": "FRETE_PAC", "quantity": "1", "price": "8,00", "unique_id": nanoid(12) },
        { "item": "DESCONTO_CUPOM", "quantity": "1", "price": "-99,00", "unique_id": nanoid(12) },
      ]
    }]);

    window.emitter.on('PriceChangeEvent', () => {
      setSapData(Lockr.get('Loovi-SAP'));
    });
  }, []);

  // const fireMetrics = () => {
  //   const userData = Lockr.get('loovi-metrics');
  //   window._etmc.push(["setOrgId", "110005701"]);
  //   window._etmc.push(["setUserInfo", {
  //     "email": userData.customerEmail,
  //     "details": {
  //       "Nome": userData.customerName,
  //       "Telefone": userData.customerPhoneNumber,
  //       "CPF": userData.customerCPF,
  //       "CEP": userData.customerCEP,
  //       "Logradouro": userData.customerStreet,
  //       "Numero": userData.customerStreetNum,
  //       "Complemento": "--",
  //       "Cidade": userData.customerCityCheck,
  //       "Estado": userData.customerUFCheck,
  //       "Cupom": userData.customerCoupom,
  //     }
  //   }]);




  //   sapData.quotation.quotationFormLines.map(item => {
  //     console.log(item)
  //     arrayDados.push([{
  //       index: 0,
  //       item_id: item.itemCode,
  //       item_name: item.itemName,
  //       affiliation: "",
  //       coupon: sapData.quotation.quotationForm.cmpgnName,
  //       discount: sapData.quotation.quotationForm.discTotal,
  //       item_brand: "Loovi",
  //       price: item.servPrice,
  //       quantity: 1
  //     }]);
  //     return arrayDados;
  //   });


  //   updateDataLayer({
  //     "email": userData.customerEmail,
  //     "Nome": userData.customerName,
  //     "Telefone": userData.customerPhoneNumber,
  //     "CPF": userData.customerCPF,
  //     "CEP": userData.customerCEP,
  //     "Logradouro": userData.customerStreet,
  //     "Numero": userData.customerStreetNum,
  //     "Complemento": "--",
  //     "Cidade": userData.customerCityCheck,
  //     "Estado": userData.customerUFCheck,
  //     "Cupom": userData.customerCoupom,
  //     "currency": "BRL",
  //     "transaction_id": sapData.quotation.quotationForm.quotID,
  //     "pagamento_frete": BRL(sapData.quotation.quotationFormFreight[0]?.priceDel),
  //     "pagamento_desconto": BRL(sapData.quotation.quotationForm.discTotal),
  //     "pagamento_valor_total": BRL(sapData.quotation.quotationForm.docTotal),
  //     "status_da_compra": "USUARIO_EM_CHECKOUT",
  //     "items": [arrayDados]
  //   });


  //   // window.gtag("event", "purchase", {
  //   //   transaction_id: sapData.quotation.quotationForm.quotID,
  //   //   value: sapData.quotation.quotationForm.docTotal,
  //   //   tax: 0,
  //   //   shipping: sapData.quotation.quotationForm.envPrice,
  //   //   currency: "BRL",
  //   //   coupon: sapData.quotation.quotationForm.cmpgnName,
  //   //   items: [arrayDados]
  //   // });


  //   // Finaliza compra e limpa carrinho
  //   window._etmc.push(["setOrgId", "110005701"]);
  //   window._etmc.push(["trackConversion", {
  //     "cart": [
  //       { "item": "LOOVI_PLANO_001", "quantity": "1", "price": "99,90", "unique_id": nanoid(12) },
  //       { "item": "FRETE_PAC", "quantity": "1", "price": "8,00", "unique_id": nanoid(12) },
  //       { "item": "DESCONTO_CUPOM", "quantity": "1", "price": "-99,00", "unique_id": nanoid(12) },
  //     ]
  //   }]);
  //   window._etmc.push(["trackPageView"]);
  // }

  const proceed = () => {
    // fireMetrics();
    Lockr.rm('HireData');
    props.nextStep()
  }

  function BRL(amount) {
    return currencyFormatter.format(amount, { code: 'BRL' });
  }

  async function handleFreteChange(e) {
    e.persist();
    const event = e;




    setIsLoading(true);

    const result = await SapService.setFreight(event.target.value);




    if (result) {
      Lockr.set('Loovi-SAP', {
        ...result
      });
      //props.handleChange(event);
      setSapData(Lockr.get('Loovi-SAP'));
      setIsLoading(false);
    } else {
      alert('Erro de Servidor. Por favor, tente novamente. (0x0003_SAP_handleFreteChange)');
      setIsLoading(false);
    }
  }

  function calcTotalAssinatura(formLines) {
    if (!formLines || !(formLines instanceof Array)) {
      return "0,00";
    }

    let total = formLines
      .filter(f => f.itemCode !== "SRV_ADESAO")
      .map(f => f.priceOfDisc)
      .reduce((prev, curr) => prev + curr, 0);

    return BRL(total || 0);
  }

  function formataValor(valor) {
    return BRL(valor);
  }

  const chargeNames = ["SRV_ADESAO", "SRV_RASTR", "SRV_ASS_MENSAL", "SRV_ASS_ANUAL"];
  const itensAssinatura = ["SRV_RASTR", "SRV_ASS_MENSAL", "SRV_ASS_ANUAL"];

  const seguros = [];

  return (
    <Section>
      <LoaderAnimation show={isLoading} />
      <Header background="#5A78FF" padding logo />
      <div className="loovi-payment-info">
        <Container>
          <Row>
            <Col sm={12} md={{ span: 4, offset: 4 }}>
              <h3>Confirmação da compra</h3>
              <div className="loovi-checkout-table">
                <table>
                  <tbody>
                    {sapData.quotation.quotationFormLines.map(item => {
                      if (item.visible) {
                        if (chargeNames.includes(item.itemCode)) {
                          if (!itensAssinatura.includes(item.itemCode)) {
                            return (
                              <tr className="big" key={item.itemCode}>
                                <th>{item.itemName}</th>
                                <td>{BRL((item.priceOfDisc || 0))}</td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr className="big" key={item.itemCode}>
                                <th>{item.itemName}</th>
                                <td>{calcTotalAssinatura(sapData.quotation.quotationFormLines)}</td>
                              </tr>
                            );
                          }
                        }
                        else {
                          if (item.itemCode !== "SRV_SEGUROS_LTI") {
                            return (
                              <tr className="child" key={item.itemCode}>
                                <th>- {item.itemName}</th>
                                <td>
                                  <div className="check">
                                    <img src="/img/check.png" width="12" alt="" />
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                          else {
                            item.salesQuotationSubItem.forEach((subItem, index) => {
                              seguros.push(
                                <tr className="child" key={subItem.itemCode}>
                                  <th>- {subItem.itemName}</th>
                                  <td>
                                    <div className="check">
                                      <img src="/img/check.png" width="12" alt="" />
                                    </div>
                                  </td>
                                </tr>
                              );
                            });
                          }
                        }
                      }


                      return <></>;
                    })}

                    {seguros}


                    <ul className="lista-checkout">
                      {sapData.quotation.statePlain
                        .filter(item => !!item.selected)
                        .reduce((acc, item) => item.plainsFormLines, [])
                        .filter(item => itensAssinatura.includes(item.itemCode))
                        .reduce((acc, item) => item.notes.split("#"), [])
                        .map(item => {
                          if (item) {
                            return <li key={item.itemCode}><small>{item}</small></li>;
                          }

                          return <></>;
                        })}
                    </ul>
                  </tbody>
                </table>
              </div>
              {sapData.quotation.quotationForm.possuiDispositivo ?
                (<h5>Frete</h5>) : (<></>)
              }
              {sapData.quotation.quotationForm.possuiDispositivo ?
                (
                  <div className="loovi-checkout-table">
                    <table>
                      <tbody>
                        {sapData.quotation.quotationFormFreight.length === 1
                          ? (
                            <tr>
                              <th>
                                {sapData.quotation.quotationFormFreight[0].typeDel} {sapData.quotation.quotationFormFreight[0].daysDel ? `- ${sapData.quotation.quotationFormFreight[0].daysDel} dia(s)` : ''}
                              </th>
                              <td>{BRL(sapData.quotation.quotationFormFreight[0].priceDel)}</td>
                            </tr>
                          ) : sapData.quotation.quotationFormFreight.map(item => (
                            <tr>
                              <th>
                                <label className="loovi-radio">
                                  {item.typeDel} {item.daysDel ? `- ${item.daysDel} dia(s)` : ''}
                                  <input
                                    type="radio"
                                    name="customerFrete"
                                    checked={item.selected}
                                    value={item.typeDel}
                                    onChange={handleFreteChange} />
                                  <span className="checkmark"></span>
                                </label>
                              </th>
                              <td>{BRL(item.priceDel)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )
                : (<></>)
              }
              <div className="loovi-checkout-table">
                <table>
                  <tbody>
                    <tr>
                      <th className="loovi-total-label">Subtotal</th>
                      <td>{BRL(sapData.quotation.quotationForm.docTotalSD)}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <th>Desconto Cupom</th>
                      <td>- {BRL(sapData.quotation.quotationForm.discTotal)}</td>
                    </tr>
                    {sapData.quotation.quotationMixPayment === null
                      ? (
                        <></>
                      )
                      : (sapData.quotation.quotationMixPayment.valor > 0
                        ? sapData.quotation.quotationMixPayment.meioPagamento === "B"
                          ? (
                            <tr>
                              <th>Boleto</th>
                              <td>- {BRL(sapData.quotation.quotationMixPayment.valor)}</td>
                            </tr>
                          )
                          : (
                            <tr>
                              <th>PIX</th>
                              <td>- {BRL(sapData.quotation.quotationMixPayment.valor)}</td>
                            </tr>
                          )
                        : (
                          <></>
                        ))
                    }
                    <tr>
                      {
                        sapData.quotation.quotationForm.parcelas > 1
                          ? (
                            <th className="loovi-total-label" >Total (parcelado em {sapData.quotation.quotationForm.parcelas}x)</th>
                          ) : (
                            <th className="loovi-total-label" >Total</th>
                          )
                      }
                      {
                        sapData.quotation.quotationMixPayment === null
                          ?
                          <td id='total-valor'>
                            {BRL(sapData.quotation.quotationForm.docTotal)}
                          </td>
                          :
                          <td id='total-valor'>
                            {BRL(sapData.quotation.quotationForm.docTotal - sapData.quotation.quotationMixPayment.valor)}
                          </td>
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
              {(!sapData.quotation.quotationForm.cmpgnID && !(sapData.quotation.quotationForm.tipoPagamento === "A") && (sapData.quotation.quotationForm.possuiDispositivo)) && <small>Sua próxima fatura será <b>{calcTotalAssinatura(sapData.quotation.quotationFormLines)}</b>, 30 dias após o recebimento do produto.</small>}
              {(!sapData.quotation.quotationForm.cmpgnID && !(sapData.quotation.quotationForm.tipoPagamento === "A") && !(sapData.quotation.quotationForm.possuiDispositivo)) && <small>Sua próxima fatura será <b>{calcTotalAssinatura(sapData.quotation.quotationFormLines)}</b>.</small>}

              <div>
                {(sapData.quotation.quotationForm.limiteFipe !== 0) && <small><br></br>Cobertura FIPE com limite de indenização de <b>{formataValor(sapData.quotation.quotationForm.limiteFipe)}</b>.</small>}
              </div>

              {sapData.quotation.quotationForm.cmpgnID && <small>{sapData.quotation.quotationForm.cmpgnText}</small>}

              <span className="loovi-spacer" />
              <FormCoupon isContation={props.isContation} handleChange={props.handleChange} values={values} />
              <span className="loovi-spacer"></span>

              <div className="loovi-total-label" style={{ fontWeight: "bold" }}>
                Dados de compra
              </div>

              <address style={{ lineHeight: 0 }}>
                {sapData.quotation.quotationFormBusinessPartner.cardName &&
                  <div>
                    <small id='nome-cliente'>{sapData.quotation.quotationFormBusinessPartner.cardName}</small>
                  </div>
                }
                {
                  (values.customerCNPJ.length <= 14) ?
                    <div>
                      <small id='cpf-cliente'>CPF: {values.customerCNPJ}</small>
                    </div>
                    :
                    <div>
                      <small>CNPJ: {values.customerCNPJ}</small>
                    </div>
                }
                {
                  values.customerEmail &&
                  <div>
                    <small id='email-cliente'>Email: {values.customerEmail}</small>
                  </div>
                }
                {
                  values.customerPhoneNumber &&
                  <div>
                    <small id='telefone-cliente'>Telefone: {values.customerPhoneNumber}</small>
                  </div>
                }
              </address>

              <div className="loovi-total-label" style={{ fontWeight: "bold" }}>
                Endereço
              </div>
              <address style={{ lineHeight: 0 }}>
                <div>
                  <small id='endereco-cliente'>Endereço: {(values.customerShippingStreet || values.customerStreet) + ', ' + (values.customerShippingStreetNum || values.customerStreetNum) + buildingShipping + ' - ' + (values.customerShippingNeighborhood || values.customerNeighborhood)}</small>
                </div>
                {(values.customerShippingCity && values.customerShippingCity.value) ?
                  <div>
                    <small id='endereco-cliente'>{values.customerShippingCity.value}/{values.customerShippingUF.value} - {masks.cep(values.customerShippingCEP)}</small>
                  </div>
                  :
                  <div>
                    <small id='endereco-cliente'>{values.customerCity.value}/{values.customerUF.value} - {masks.cep(values.customerCEP)}</small>
                  </div>
                }
              </address>

              {(!props.isContation) && (
                <ButtonBack className="loovi-link-button loovi-link-button-payment" onClick={() => props.goToStep(1)} type="button">Alterar dados</ButtonBack>
              )}

              { /* <div className="loovi-user-data d-flex align-items-center">
                          <div className="loovi-user-data_col1">
                              <h6>Dados Pessoais</h6>
                          </div>
                          <div className="loovi-user-data-col-2">
                              <button onClick={setModalContactDataOpen}>Editar</button>
                              <ModalContactData
                                  handleChange = {props.handleChange}firstStep
                                  values = {props.values}
                                  show={modalContactDataShow}
                                  onHide={setModalContactDataClose} />
                          </div>
                      </div>
                      <div className="loovi-user-data  d-flex  align-items-center">
                          <div className="loovi-user-data_col1">
                              <h6>Dados de Endereço</h6>
                          </div>
                          <div className="loovi-user-data-col-2">
                              <button onClick={setModalAddressOpen}>Editar</button>
                              <ModalAddress
                                  handleChange = {props.handleChange}
                                  handleSelectChange = {props.handleSelectChange}
                                  values = {props.values}
                                  show={modalAddressShow}
                                  onHide={setModalAddressClose}/>
                          </div>
                                </div> */ }
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col sm={12} md={{ span: 4, offset: 4 }}>
            <Body>
              <h4 className="loovi-color-2 loovi-card-ico">Dados do Cartão</h4>
              <FormCreditCard proceed={proceed} />
            </Body>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const ButtonBack = styled.a`
  color: #57D9A3 !important;
  &:hover { color: #57D9A3 !important; }
`

const Section = styled.section`
    position: relative;

    .loovi-total-label{

        font-size: 18px !important;
        font-family: 'BrownStd-Regular' !important;
    }

    .loovi-card-ico{

        display: inline-block;
        padding-right: 50px;
        background-image: url(${cardIcon});
        background-repeat: no-repeat;
        background-size: 31px 25px;
        background-position: right 3px;
    }

    .loovi-cupom{

        &__col1{

            padding-right: 10px;
        }

        .loovi-link-button-payment {
          align-self: center;
        }

        button{

            height: 50px;
            padding: 0 30px;
            border-radius: 5px;
            color: #FFFFFF;
            background: #8ba0ff;
            font-size: 13px;
            text-transform: uppercase;
            border: none;

            &:hover{

                color: #57D9A3;
            }
        }
    }

    .checkmark{

        background: #EFF2F7 !important;
    }

    .loovi-payment-info{

        background: #5A78FF;
        color: #FFFFFF;
        padding-bottom: 35px;

        h3{

            font-size: 24px;
            color: #FFFFFF;
            letter-spacing: -0.86px;
            text-align: center;
            line-height: 30px;
            padding-bottom: 15px;
            border-bottom: solid 1px rgba(100%, 100%, 100%, .1);
            margin: 0;
        }

        h5{

            font-family: 'BrownStd-Regular';
            font-size: 18px;
            letter-spacing: -0.64px;
            line-height: 26px;
            margin: 0;
        }

        small{

            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 18px;
        }

        .loovi-checkout-table{

            padding: 15px 0;
            margin-bottom: 15px;
            border-bottom: solid 1px rgba(100%, 100%, 100%, .1);

            table{

                width: 100%;

                th{

                    font-size: 14px;
                    letter-spacing: -0.5px;
                    line-height: 26px;
                    padding-right: 20px;
                }

                td{

                    text-align: right;
                }
            }
        }
    }

    .loovi-user-data{

        color: #FFFFFF;
        margin-bottom: 15px;

        button{

            font-size: 15px;
            color: #FFFFFF;
            background: transparent;
            border: none;
            border-bottom: solid 2px #FFFFFF;
            margin: 0;
            padding: 0;
            line-height: 15px;
        }

        h6{

            font-size: 15px;
            letter-spacing: -0.32px;
            line-height: 21px;
            margin: 0 15px 0 0;
        }

        p{

            margin: 0;
            font-size: 15px;
        }

        a{

            color: #FFFFFF;
            text-decoration: underline;
        }
    }
`

export default FormPayment